.table {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0 auto 0rem;
    max-width: 1200px;
}

.tr {
    display: table-row;
    border-bottom: 1px solid #ccc;

    &:last-of-type {
        border-bottom: 0px solid #ccc;
    }

    &:nth-last-of-type(2) {
        border-bottom: 0px solid #ccc;
    }
}

.td {
    display: table-cell;
    padding: 1.75rem .5rem;
    font-size: 1rem;
}

.table label {
    font-size: 14px;
}

@media (max-width: 800px) {
    .table,
    .tr, 
    .td {
        display: block;
        text-align: center;
    }
}

.calculator {
    padding: 2rem 3rem;
    background-color: #e7f1fa;
    font-family: 'Open Sans', sans-serif;
}

.calculator .input,
.calculator .output {
    margin-bottom: 0;
}

.calculator .input {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    display: inline-block;
    max-width: 100%;
    background-color: transparent;
    font-family: 'Open Sans', sans-serif;
    font-size: 2rem;
    font-weight: bold;
    color: #149b9e;
    box-sizing: border-box;
}


.calculator .currency {
    margin-bottom: .5rem;
    font-size: 2rem;
    font-weight: bold;
    color: #149b9e;
}

.calculator .total .output {
    font-size: 1.3rem;
    padding: .5rem 0;
}

.calculator .total .output.currency {
    font-size: 3rem;
    padding: 1rem 0;

}

.calculator .calculator-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .title {
        margin-bottom: 0;
        // font-weight: bold;
        // font-family: $body;
    }

}

.calculator .condominium {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .output {
        margin: 0 1rem;
    }
}

.calculator-reset {
    appearance: none;
    border: 0;
    background-color: #149b9e;
    padding: .75rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 300;
    text-transform: uppercase;
    transition: all .3s ease;
    color: #fff;

    &:hover {
        background-color: darken( #e7f1fa, 20%);
        transition: all .3s ease;
    }
}

.calculator .style-select {
    width: 280px;
}

.calculator .ss-selected-option {
    background-color: #149b9e;
    background-image: none;
    padding: .75rem 1rem;
    border: 0;
    color: #fff;
    font-size: 14px;
}

.calculator .style-select .ss-selected-option::after {
    top: 9px;
}

.calculator .style-select .ss-dropdown {
    top: 40px;
}

@media (max-width: 800px) {

    .calculator .input {
        width: 100%;
        text-align: center;
    }

    .calculator .calculator-header {
        justify-content: center;
        flex-direction: column;

        .title {
            margin-bottom: 1rem;
        }

        .output {
            margin: 0;
            margin-left: 1rem;
        }
    }

    .calculator .style-select  {
        margin: 0 auto;

    }
}

@media (max-width: 500px) {

    .calculator {
        padding: 1rem;
    }

    .calculator .calculator-header .condominium {
        flex-direction: column;

        .output {
            margin-top: 1.25rem;
            margin-left: 0rem;
        }
    }
}


// Select fallback for iOS
.select {
    appearance: none;
    background-color: #149b9e;
    background-image: none;
    padding: .75rem 2rem;
    border: 0;
    color: #fff;
}
