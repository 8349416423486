// Colours
$black: #000;
$white: #fff;

// Main
$green: #149b9e;

// Backgrounds
$bg--blue: #e7f1fa;

$bg: $white;

$transition: all .3s ease-out;

// Layering

$z-under: -1;
$z-bottom: 1;
$z-mid: 99;
$z-top: 9999;


// Type setting
// set the body font
$type-body-font: 'Open Sans', sans-serif;
$type-title-font: 'Georgia', serif;

$body: $type-body-font;
$title: $type-title-font;
$bold: $title;

// set the base body size
$type-body-size: 16px;

// rhythm
// set the line-height
$type-leading: 1.5;

// set an increment for more control adjusting line-heights, paddings and margins
// used by rhythm(4) function
$type-rhythm: $type-leading / 4;

// set the type scale for working with headings etc
// used with @include scale(1);
$type-scale: 1.25;
