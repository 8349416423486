.flexible-layout {
    display: flex;
    align-items: center;
    padding: rhythm(8) rhythm(4);
    border-top: 1px solid #ddd;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.flexible-layout.reverse {
    flex-direction: row-reverse;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
    }
}

.flexible-image {
    flex: 2;
    padding-left: rhythm(16);
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 600px) {
        padding: rhythm(4);
    }
}

.flexible-content {
    flex: 3;
}

.flexible-layout.reverse .flexible-image {
    padding-left: 0;
    padding-right: rhythm(16);

    @media (max-width: 600px) {
        padding: rhythm(4);
    }
}
