.footer {
    font-size: 12px;
    padding: 20px 0 10px;
    background-color: $green;
    color: $white;

    @media (max-width: 600px) {
        text-align: center;
    }
}

.footer a:hover {
    color: $black;
}

.footer-menu li {
    display: inline-block;

}

.footer-menu li a {
    padding: rhythm(2) rhythm(2) rhythm(2) 0;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1px;

}

/*-- Footer Menu ---*/

.footer-menu a {
}

.footer-menu li {
    margin-left: 10px;
}

.footer-menu li:after {
    content: "|";
    margin-left: 10px;
}

.footer-menu li:last-child:after {
    content: none;
    margin: 0;
}

.footer-menu li:first-child {
    margin-left: 0px;
}
