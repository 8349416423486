.quote {
    padding: rhythm(4);
    background-color: $bg--blue;
    display: block;
    margin: 0 auto;
    text-align: center;
}

.quote-title {
    color: $green;
    font-family: $title;
    @include scale(2);
}

.quote-citation {
    color: $green;
    font-family: $title;
    @include scale(2);
    margin-bottom: 0;
}
