.header-menu {
    position: relative;
    text-align: center;
    width: 100%;

    @media (max-width: 800px) {
        display: none;
    }
}

.main-menu {
    display: inline-block;
    font-family: $body;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 1;
    position: relative;
    width: 100%;
}

.main-menu li {
    display: inline-block;
}

.main-menu a {
    display: inline-block;
    padding: .75rem;
    text-decoration: none;
    transition: all ease .3s;
    text-transform: uppercase;
    font-size: 12px;
}

.main-menu > li:hover > a {
    color: $green;
    transition: all ease .3s;
}

.main-menu > li > a {
    color: $black;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        right: -.25rem;
        top: 50%;
        transform: translateY(-50%);
        height: 1rem;
        background-color: $green;
        width: 1px;
    }
}

.main-menu > li:last-of-type > a::after {
    display: none;
}

/*
 * Submenu
 */

.main-menu .sub-menu {
    display: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;

    &::after {
        content: '';
        display: block;
        background-color: $white;
        width: 100%;
        height: 2.4rem;
        position: absolute;
        z-index: 0;
    }

    &::before {
        content: '';
        height: 1px;
        background-color: #ccc;
        display: block;
        width: 50%;
        margin: 0 auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: $z-top;
    }
}

.main-menu li.current-menu-parent .sub-menu,
.main-menu li.current-menu-item .sub-menu,
.main-menu li:hover .sub-menu {
    display: flex;
    position: absolute;
    z-index: 99;
    justify-content: center;
}

.main-menu li:hover .sub-menu {
    z-index: 999;
}

.main-menu .sub-menu li {
    float: none;
    position: relative;
    display: inline-block;
    z-index: 99;
}

.main-menu .sub-menu a {
    display: inline-block;
    padding: .8rem .5rem;
    text-align: left;
    color: $black;

    &:hover {
        color: $green;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 1rem;
        background-color: $green;
        width: 1px;
    }
}

.main-menu .sub-menu li:last-of-type a::after {
    display: none;
}

.main-menu .current-menu-item > a {
    color: $green;
}

.main-menu .sub-menu .current-menu-item > a {
    color: $green;
}

// Mobile sub menu

.mobile-menu-wrap { 
    display: none;

    @media (max-width: 800px) {
        display: block;
    }
}

.mobile-toggle {
    padding: .75rem;
    display: inline-block;
    width: 100%;
    text-align: center;
    text-decoration: none;
    background-color: $bg--blue;
    color: $black;
}


.mobile-menu {
    height: 0;
    overflow: hidden;
    transition: height .3s ease;
    display: block;
}

.mobile-menu a {
    padding: .5rem;
    display: block;
    text-align: center;
    text-decoration: none;
    border-top: 1px solid #ccc;

    &:hover {
        background-color: $bg--blue;

    }
}

.mobile-menu li:last-of-type a {
    border-bottom: 1px solid #ccc;
}

.mobile-menu .current-menu-item {
    color: $green;
}
