/*--- Mobile Menu ---*/

// .mobile-menu,
// .mobile-main-menu.full {
// 	width: 100%;
// }
//
// .mobile-main-menu,
// .mobile-sub-menu {
// 	float: left;
// 	width: 50%;
// }
//
// .mobile-sub-menu {
// 	background-color: #eee;
// }
//
// .mobile-menu-container {
// 	text-align: center;
// 	padding-bottom: 14px;
// 	overflow: hidden;
// }
//
// .mobile-menu {
// 	display: none;
// }
//
// .mobile-toggle,
// .mobile-menu li,
// .mobile-menu a {
// 	display: block;
// }
//
// .mobile-toggle,
// .mobile-menu a {
// 	text-decoration: none;
// 	padding: 5px 0;
// 	border-bottom: 1px solid #ddd;
// }
//
// .mobile-main-menu a {
// 	border-right: 1px solid #ddd;
// }
//
// .mobile-sub-menu a {
// 	border-left: 1px solid #ddd;
// }
//
// .mobile-toggle:hover,
// .mobile-menu a:hover {
// 	background-color: #2aa7df;
// 	color: #fff!important;
// }
