.full-width-image {
    margin: 0 auto;
    width: 100%;
}

.grid {
    display: flex;

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.grid-item {
    flex: 1;
    display: block;
    padding: rhythm(0) rhythm(2) rhythm(2) rhythm(0);

}

.grid-item:last-of-type {
    padding-right: 0;
}
