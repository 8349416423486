hr {
  margin: 20px 0;
  padding: 0;
  border: 0;
  height: 0;
  border-top: 1px solid #ddd;
}

.center {
  text-align: center;
}

.top-menu {
  margin-bottom: 0;
  text-align: right;
}

.social {
  display: inline;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.no-margin {
  margin: -20px;
}

/*-- Header Image ---*/

#header {
  // height: 100px;
  overflow: hidden;
  position: relative;
  background-color: $green;
}

.header-img {
  width: 100%;
  height: auto;
  min-height: 100px;
  position: absolute;
}

#logo {
  // position: absolute;
  width: 100%;
  z-index: 99;
  top: 0;
}

#logo .logo-img {
  margin: 0 auto;
  display: block;
  width: 600px;
  max-width: 100%;

  img {
    margin-bottom: 0;
  }
}

/* shadow */
.shadow-top,
.shadow-bottom {
  display: block;
  position: relative;
  z-index: 999;
  margin: 0 auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 15px;
  max-width: 1100px;
  width: 90%;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.shadow-top {
  background: url("./../../img/shadow-top.png");
}

.shadow-bottom {
  background: url("./../../img/shadow-bottom.png");
}

.adjust {
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
}

#menu-bar {
  position: relative;
  top: -15px;
  // margin-bottom: 40px;
  background-image: url("./../../img/shadow-bottom.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  /*height: 60px;*/
}

.hr-right {
  border-right: 1px solid #eee;
  padding-right: 5px;
}

.hr {
  display: block;
  clear: both;
  width: 80%;
  max-width: 960px;
  margin: 20px auto 40px;
  border: 0;
  border-top: 1px solid #ddd;
}

/*-- Forms --*/

input {
  border: 0;
}

/*-- Property --*/

.property-title {
  color: #03979d;
  margin-bottom: 5px;
}

.property-title a {
  text-decoration: none;
}

.property-details {
  background-color: #e7f1fa;
  padding: 10px;
  margin-bottom: 20px;
}

.property-details li {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 20px;
}

.property-details .title {
  list-style-type: none;
  width: 100%;
  float: none;
  clear: both;
  font-size: 14px;
  margin-bottom: 10px;
}

.property-contact {
  text-align: center;
  font-size: 11px;
}

.property-logo {
  display: block;
  margin: 0 auto;
  max-width: 75%;
}

.property-enquire {
  text-align: center;
  margin: 20px 0;
}

.btn {
  display: inline-block;
}

.btn:hover {
  cursor: pointer;
}

.btn-blue {
  background-color: #1c89ba;
  background: -webkit-linear-gradient(top, #03979d, #1c89ba);
  background: -moz-linear-gradient(top, #03979d, #1c89ba);
  background: -o-linear-gradient(top, #03979d, #1c89ba);
  background: -ms-linear-gradient(top, #03979d, #1c89ba);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#03979D, endColorstr=#1c89ba);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  color: #fff;
  padding: 10px 5px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 2px;
  min-width: 150px;
}

.btn-blue:hover {
  color: #fff !important;
  background-color: #03979d;
  background: -webkit-linear-gradient(top, #6dc2e9, #03979d);
  background: -moz-linear-gradient(top, #6dc2e9, #03979d);
  background: -o-linear-gradient(top, #6dc2e9, #03979d);
  background: -ms-linear-gradient(top, #6dc2e9, #03979d);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#6dc2e9, endColorstr=#03979D);
}

.btn-blue:active {
  background-color: #03979d;
  background: -webkit-linear-gradient(top, #03979d, #6dc2e9);
  background: -moz-linear-gradient(top, #03979d, #6dc2e9);
  background: -o-linear-gradient(top, #03979d, #6dc2e9);
  background: -ms-linear-gradient(top, #03979d, #6dc2e9);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#03979D, endColorstr=#6dc2e9);
}

.press,
.property-list {
  clear: both;
  overflow: hidden;
  margin-bottom: 20px;
}

.property-list img {
  width: 100%;
  height: auto;
}

.press-title {
  margin-bottom: 5px;
}

.overlay-box {
  position: relative;
}

.overlay-box img {
  /*  width: 100%;
  min-height: 100%;
  height: auto;*/
}

.overlay-box .overlay {
  background-color: rgba(255, 255, 255, 0.85);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/*--- Post ---*/

.blog-prev-link a,
.blog-next-link a {
  display: inline-block;
  padding: 5px;
  background-color: #ccc;
  color: #666;
  text-decoration: none;
}

.blog-prev-link a:hover,
.blog-next-link a:hover {
  background-color: #03979d;
  color: #fff;
}

.blog-next-link {
  float: right;
}

.post {
  overflow: hidden;
  clear: both;
  border-bottom: 1px solid #eee;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.post-title {
  margin-bottom: 5px;
}

.post-archive .post-thumbnail {
  float: left;
  margin: 0 20px 0 0;
}

.post-single .post-thumbnail {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.post-meta {
  margin-bottom: 20px;
  font-size: 12px;
  color: #999;
}

.post-categories,
.post-categories li {
  display: inline;
}

/*--- Sidebar ---*/

.widget {
  margin-bottom: 20px;
}

.widget-title {
  margin-bottom: 10px;
}

/*--- Page Content ---*/

.content ul,
.content ol {
  margin-left: 20px;
}

.content ul li {
  list-style-type: disc;
}

.content ol li {
  list-style-type: decimal;
}

.content strong,
.content b {
  font-weight: bold;
}

/*--- Tablepress ---*/

#tablepress-1,
#tablepress-2 {
  width: auto;
  margin: 0 auto 20px;
}

/*--- Social Links ---*/

.social-link {
  text-decoration: none;
  text-transform: uppercase;
}

.social-link:hover {
  text-decoration: none;
}

.social {
  vertical-align: top;
}

.gdpr-form {
  text-align: center;
}

.gdpr-form label {
  font-size: 1rem;
}

.gdpr-form input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  width: 50%;
  margin-top: 1rem;
  font-size: 1rem;
}

.gdpr-form .js-cm-submit-button {
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  background-color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 1rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.gdpr-form .js-cm-submit-button:hover {
  background-color: #e7f1fa;
  transition: all 0.3s ease;
}
