$ss-line-height: 19px !default;
$ss-border-width: 1px !default;
$ss-spacing: 24px !default;

$ss-highlight-color: rgb(242, 244, 248) !default;
$ss-selected-option-background: linear-gradient(to bottom, #fefefe, #f2f2f2) !default;
$ss-dropdown-shadow: 0 1px 3px rgba(0, 0, 0, 0.1) !default;

$ss-standard-border: $ss-border-width solid #d0d0d0 !default;

$ss-spacing-small: $ss-spacing / 2 !default;
$ss-spacing-tiny: $ss-spacing / 4 !default;

// Hide real select boxes which have seen styled.
select[data-ss-uuid] {
	display: none;
	pointer-events: none;
}

.style-select {
	display: block;
	position: relative;
	width: 140px; // Default width

	// Set reasonable defaults on this element and its children
	&, * {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		text-align: left;
		line-height: $ss-line-height;
		color: black;
		font-weight: 300;
		cursor: pointer;
	}

	.ss-selected-option {
		width: 100%;
		background-image: $ss-selected-option-background;
		border-radius: 2px;
		padding: $ss-spacing-tiny;
		border: $ss-standard-border;
		// The down arrow
		&::after {
			content: "▾";
			position: absolute;
			top: $ss-spacing-tiny;
			right: $ss-spacing-small;
			display: block;
		}
	}

	.ss-dropdown {
		top: $ss-line-height + 2 * $ss-spacing-tiny + $ss-border-width;
		left: 0;
		position: absolute;
		float: left;
		width: 100%;
		display: none;
		box-shadow: $ss-dropdown-shadow;
		border: $ss-standard-border;
		border-top: 0 none;
		.ss-option {
			width: 100%;
			padding: $ss-spacing-tiny;
			background-color: white;
			// We don't use :hover as that's only for mouse
			// and we want keyboard highlighting too
			&.highlighted {
				background-color: $ss-highlight-color;
			}
			&.disabled {
				color: #d0d0d0;
			}
			&.ticked::before {
				content: "✓";
				padding-right: 3px;
			}
		}
	}

	&.open {
		.ss-dropdown {
			display: block;
		}
		.ss-selected-option::after {
			content: "▴";
		}
	}

}
