h1,
.alpha,
h2,
.beta,
h3,
.gamma,
h4,
.delta,
h5,
.epsilon,
h6,
.zeta {
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1.5;
  font-family: $title;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: $green;
}

h1,
.alpha {
    @include scale(5);
    line-height: rhythm(8);
}

h1 {
    margin-bottom: rhythm(5);
}

h2,
.beta {
    @include scale(4);
    line-height: rhythm(6);
}

h2 {
    margin-bottom: rhythm(5);
}

h3,
.gamma {
    @include scale(3);
    line-height: rhythm(5);
}

h4,
.delta {
    @include scale(2);
    line-height: rhythm(5);
}

h5,
.epsilon {
    @include scale(2);
    line-height: rhythm(5);
}

h6,
.zeta,
.zeta a {
    @include scale(1);
    line-height: rhythm(5);
}

.micro,
.micro a {
    font-size: .825rem;
    line-height: 1rem;
}

h1 a,
h2 a,
h3 a,
h4 a {
  text-decoration: none;
}

p {
    @include scale(1);
}

.standfirst {
  font-size: 1.16em;
  letter-spacing: 1px;
  line-height: 1.5;
}

.content img {
    // margin-bottom: rhythm(7);
}

.content img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.content ul {
    margin-bottom: rhythm(4);
}
