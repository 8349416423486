// Base Styles
* {
    box-sizing: border-box;
}

html,
body {
    background-color: $bg;
    color: $black;
    font-weight: 300;
}

// Links

a,
a:link,
a:visited,
a:active,
a:hover {
    color: inherit;
    // text-decoration: none;
    transition: $transition;
}

a:hover {
    // text-decoration: none;
    transition: $transition;
      cursor: pointer;
      color: $green;
}
