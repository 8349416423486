.slideshow--main .slide {
    width: 66%;
    margin: 0 rhythm(2);
}

.flickity-page-dots {
    bottom: rhythm(4);

    .dot {
        background: $white;
    }
}
